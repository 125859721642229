export const tableColumns = {
  title: {
    formatDataKey: "Title",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  description: {
    formatDataKey: "Description",
    formatDataValue: (arg: any) => (arg ? arg : "N/A"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 1
  }
};
