
import Vue from "vue";
import { ITableWrapperColumns } from "@/types";
import { tableColumns } from "./documentCategoryColumns";
import { atlasfileMapActions } from "@/store/modules/atlasfile";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import {
  atlasFileCategoryMapActions,
  atlasFileCategoryMapGetters,
  atlasFileCategoryMapMutations,
  atlasFileCategoryMapState
} from "@/store/modules/atlasFileCategory";

interface IListUsersData {
  loading: boolean;
  tableActions: any[];
  tableColumns: ITableWrapperColumns;
  tableRowActions: any[];
  errorMessage: string;
  documentTitle: string;
  categoryId: string;
  loadingText: string;
}
interface IActions {
  text: string;
  key: string;
  subItems: { title: string; command: string }[];
}
export default Vue.extend({
  name: "listAtlasFilesCategories",
  components: {
    NoResultsFound
  },
  data(): IListUsersData {
    return {
      loading: false,
      tableActions: [],
      tableColumns: tableColumns,
      errorMessage: "",
      documentTitle: "",
      categoryId: "",
      tableRowActions: [
        { label: "Edit", value: "edit", faIcon: "edit" },
        {
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        }
      ],
      loadingText: ""
    };
  },
  methods: {
    ...atlasfileMapActions(["getAtlasfiles", "deleteAtlasFile"]),
    ...atlasFileCategoryMapMutations(["SET_ITEM", "SET_EDIT"]),
    ...atlasFileCategoryMapActions([
      "getAtlasFileCategories",
      "deleteAtlasFileCategory"
    ]),
    async handleResponseAction(payload: {
      name: string;
      data: Record<string, any>;
    }) {
      switch (payload.name) {
        case "edit":
          this.SET_EDIT(payload.data as any);
          this.$router
            .push(`/admin/document-categories/${payload.data._id}`)
            .catch(() => {});
          break;
        case "delete":
          try {
            this.loading = true;
            const { _id } = payload.data;
            this.categoryId = _id;
            await this.confirmDelete();
          } catch (error) {
            this.$appNotifyError("Error deleting category. Try again later.");
            this.$bugSnagClient.notify(error);
          } finally {
            this.loading = false;
          }
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async confirmDelete() {
      this.$modal.show("atlasFileCategoryDeleteModal");
    },
    async deleteOneAtlasFileCategory() {
      await this.deleteAtlasFileCategory(this.categoryId);
      this.$modal.hide("atlasFileCategoryDeleteModal");
      this.$appNotifySuccess("Category deleted successfully");
    },
    async onToolbarItemClick(event: string) {
      switch (event) {
        case "add-category":
          this.$router.push("/admin/documents/category/new");
          break;
        case "document-category-bulk-activities":
          this.$router
            .push(`/admin/documentCategories/activities`)
            .catch(() => {});
          break;
        default:
          return null;
      }
    },
    async fetchCategories(queryObject: any): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Loading data. Please wait...";
        this.$emit("scrollTop");
        await this.getAtlasFileCategories({ query: queryObject });
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      this.$router.push(`/admin/document-categories/${_id}`).catch(() => {});
    }
  },
  computed: {
    ...atlasFileCategoryMapState([
      "atlasFileCategories",
      "links",
      "makingApiRequest"
    ]),
    ...atlasFileCategoryMapGetters([
      "getTotalRecords",
      "getAtlasFileCategoryLinks"
    ]),
    primaryButton() {
      return {
        text: "Add Category",
        key: "add-category"
      };
    },
    topButtonSecondaryItems(): IActions {
      const primary = {
        text: "Actions",
        key: "actions"
      };
      const subOptions: IActions["subItems"] = [
        {
          title: "All Activities",
          command: "document-category-bulk-activities"
        }
      ];

      return {
        ...primary,
        subItems: subOptions
      };
    }
  }
});
